import Card from "./components/Card";
import Mosaico from "./components/Mosaico";
import Banner from "./components/Banner";
import ContactFooter from "./components/ContactFooter";
import LinkImage from "./components/LinkImage";


import "./styles/index.css";

function App() {
  return (
    <div className="App">
      <Banner></Banner>
      <div>
        <h1 class="coleccion">NUEVA COLECCIÓN</h1>
      </div>
      <div className="d-flex justify coleccion">
        <Card></Card>
        <Card></Card>
        <Card></Card>
        <Card></Card>
      </div>

      <div className="justify-content coleccion">
        <LinkImage></LinkImage>
      </div>

      <div className="d-flex justify">
      <Mosaico></Mosaico>
      </div>

      <div>
        <h1 class="coleccion">AYUDAS PARA LA MARCHA</h1>
      </div>
      <div className="d-flex justify coleccion">
        <Card></Card>
        <Card></Card>
        <Card></Card>
        <Card></Card>
      </div>
      <div>
        <h1 class="coleccion">TERAPIAS RESPITATORIAS</h1>
      </div>
      <div className="d-flex justify coleccion">
        <Card></Card>
        <Card></Card>
        <Card></Card>
        <Card></Card>
      </div>
      <footer class="textoFooter">
        <ContactFooter>
          <hr></hr>
        </ContactFooter>
      </footer>
    </div>
  );
}

export default App;
