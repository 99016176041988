import React, { Component } from "react";
import "../styles/index.css";
import silla from "../assets/silla1.png";

export default class Card extends Component {
  constructor(props) {
    super(props);

    // Setting up state
    this.state = {
      description:
        "Silla de ruedas motorizada gris metalizada abatible M2000.",
      cardName: "Silla Metálica",
      link: "Añadir al carrito",
      img: "/public/img/silla1.png",
    };
  }

  render() {
    return (
      <article className="card">
        <img src={silla} />
        <h4 cardName="card__title">{this.state.cardName}</h4>
        <hr />
        <div>
          <p>{this.state.description}</p>
        </div>
        <a href="/">
          <button>{this.state.link}</button>
        </a>
        <br></br>
      </article>
    );
  }
}
