import React, { Component } from "react";
import "../styles/index.css";
import cuadro1 from "../assets/cuadro1.png";
import cuadro2 from "../assets/cuadro2.png";

export default class LinkImage extends Component {
  constructor(props) {
    super(props);

    // Setting up state
    this.state = {
/*       img: "https://media-cdn.tripadvisor.com/media/photo-s/0c/bb/a3/97/predator-ride-in-the.jpg",
      description: "Este es texto de relleno",
      link: "Algo", */
    };

  }

  render() {
    return (
      <article className="d-flex justify coleccion">
        <img src={cuadro1} alt="empresas en crecimiento" />
        <div>
          <p>{this.state.description}</p>
        </div>

        <img src={cuadro2} alt="complemento linea de oximetria" />
        <div>
          <p>{this.state.description}</p>
        </div>
      </article>
    );
  }
}
