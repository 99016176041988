import React, { Component } from "react";
import "../styles/index.css";
import banner from "../assets/banner1.png";

export default class Banner extends Component {
  constructor(props) {
    super(props);

    // Setting up state
    this.state = {
      img: "/public/img/banner1.png",
      description: "Este es texto de relleno",
      link: "Ver Productos",
    };
  }

  render() {
    return (
      <article className="banner1">
        <img src={banner}/>
        {/* <img src={this.state.img} alt="banner1" className="banner1" /> */}
        <div cardName="banner1">
          <p>{this.state.description}</p>

          <a href="/">
            <button>{this.state.link}</button>
          </a>
        </div>
      </article>

    );
  }
}
