import React, { Component } from "react";
import "../styles/index.css";
import pagos from "../assets/pagos.png"

export default class ContactFooter extends Component {
  constructor(props) {
    super(props);

    // Setting up state
    this.state = {};
  }

  render() {
    return (
      <footer className="footer">
        <article className="footer--card">
          <h6 className="footer--card__title footerEStilo"> SOBRE NOSOTROS</h6>
          <ul>
            <li>
              <a href="#">
                <p className="textoFooter">¿Quienes Somos?</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">¿Nuestra historia?</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">¿Nuestra información?</p>
              </a>
            </li>
          </ul>
        </article>
        <article className="footer--card">
          <h6 className="footer--card__title footerEStilo">CATEGORÍAS DESTACADAS</h6>
          <ul>
            <li>
              <a href="#">
                <p className="textoFooter">Profesionales de la salud</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">Protección e higiene</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">Control y prevención</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">Movilidad</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">Independencia y cuidado en el hogar</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">Terapia respiratoria</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">Confort</p>
              </a>
            </li>
          </ul>
        </article>
        <article className="footer--card">
          <h6 className="footer--card__title footerEStilo">SERVICIO AL CLIENTE</h6>
          <ul>
            <li>
              <a href="#">
                <p className="textoFooter">Política de entregas</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">Política de devoluciones</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">Política de garantías</p>
              </a>
            </li>
            <li>
              <a href="#">
                <i class="fab fa-whatsapp textoFooter"></i>
                <p className="textoFooter">Whatsapp 123 123 3434</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">PBX 01 8000 123 345</p>
              </a>
            </li>
            <li>
              <a href="#">
                <p className="textoFooter">e-Mail:info@impormedical.com</p>
              </a>
            </li>
          </ul>
        </article>
        <article className="footer--card">
          <h6 className="footer--card__title footerEStilo">SUSCRÍBASE A NUESTRO NEWSLETTER</h6>
          <input className="footer--card__input" placeholder="Contactenos" />
          <hr />
          <img width="95%" src={pagos} title="medioPagos"/>
        </article>
      </footer>
    );
  }
}
